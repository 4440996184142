import React from 'react';
// import PropTypes from 'prop-types';
import { ComponentWrapper } from './Footer.css';

const Footer = (props) => {
  // const { title, copy } = props;
  return (
    <ComponentWrapper className={`c-Footer`}>
	    <img src="https://webcdn.hirezstudios.com/redbeardgames/Logos/RBG_Final-14.png" />
    	<div className="text-container">
	 			<a href="https://webcdn.hirezstudios.com/hirez-studios/wp-content/uploads/2015/11/Hi-Rez-Studios-Online-Terms-of-Service-and-Use-Agreement-Last-updated-Aug.-24-2017.pdf" target="_blank" rel="noopener noreferrer">Terms of Service</a>
				<a href="https://www.hirezstudios.com/privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
				<div className="divider" />
				<div className="ip-notice">© {new Date().getFullYear()} Hi-Rez Studios, Inc. "Red Beard Games" and "Hi-Rez Studios" are trademarks or registered trademarks of Hi-Rez Studios, Inc. in the U.S. and/or other countries. All trademarks and copyright material are property of their respective owners. All rights reserved.</div>
			</div>
    </ComponentWrapper>
  );
};

// Footer.propTypes = {
//   title: PropTypes.string.isRequired,
//   copy: PropTypes.string.isRequired
// };


export default Footer;