import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  align-items: center;
  background-color: #881533;
  display: flex;
  color: #300004;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 2rem 2rem 2rem 4rem;
  .text-container {
    margin-left: auto;
    max-width: 50rem;
    @media (max-width: 600px) {
      margin: 1rem auto;
    }
  }
  a {
    color: #300004;
    margin: 0 1rem;
    text-decoration: none;
    &:hover {
      color: #FFF;
    }
  }
  .divider {
    background-color: #300004;
    height: 1px;
    margin: 1rem; 0;
    max-width: 40rem;
    width: 100%;
  }
  .ip-notice {
    color: #FFF;
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 1rem 1rem;
    max-width: 50rem
  }
  img {
    max-width: 14rem;
    @media (max-width: 600px) {
      display: none;
    }
  }
`;