import React, { Component } from 'react';
import { Link } from 'gatsby';
import { ComponentWrapper } from './SiteNavigation.css';

class SiteNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  toggleMenu = () => {
    if (this.state.menuOpen === false) {
      this.setState({ menuOpen: true});
    }
    else {
      this.setState({ menuOpen: false});
    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <ComponentWrapper id="c-SiteNavigation">
        <div onClick={()=>this.toggleMenu()} className="menu-action">
          <div className={`icon-menu ${this.state.menuOpen === true ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <Link to="/"><img src="https://webcdn.hirezstudios.com/redbeardgames/Logos/RBG_Final-07.png" alt="Red Beard Games Logo" /></Link>
        <div className={`links-wrapper ${this.state.menuOpen === true ? 'open' : ''}`}>
          <Link to="/careers">Careers</Link>
          <Link to="/about">About</Link>
          {/*<Link to="/press">Press</Link>*/}
          <div className="social-links">
            <a className="facebook" href="https://www.facebook.com/PlayRedBeard" target="_blank" rel="noopener noreferrer" />
            <a className="twitter" href="https://twitter.com/RedBeardGames" target="_blank" rel="noopener noreferrer" />
            <a className="instagram" href="https://www.instagram.com/PlayRedBeard/" target="_blank" rel="noopener noreferrer" />
          </div>
        </div>
      </ComponentWrapper>
    );
  } 
};

export default SiteNavigation;